<template>
  <el-main v-if="info.id">
    <div class="order-info">
      <div class="title">
        订单信息
        <div v-if="info.store_id">
          <el-tag type="success">工厂店服务</el-tag>
          （{{ info.shop_info.store_name }}）
        </div>
        <el-tag v-else style="margin-left: 15px" type="primary" size="small">平台服务</el-tag>
      </div>
      <div class="order-content">
        <div class="order-title">
          <p>
            退款单号：
            <span>{{ refundInfo.refund_sn }}</span>
          </p>
          <p>
            订单编号：
            <span>{{ info.pay_sn }}</span>
          </p>
          <p>
            提交时间：
            <span>{{ info.create_time }}</span>
          </p>
        </div>
        <div class="order-process" v-if="[0, 20, 30].includes(refundInfo.handle_state)">
          <div class="item" style="background: #ddddfd">
            <div class="num" style="background: #409eff; color: #fff">1</div>
            <p class="handle-text" style="color: #409eff">提交时间</p>
            <p class="handle-time">{{ step.time0 }}</p>
          </div>
          <div class="line"></div>
          <div class="item" style="background: #ddddfd" :style="{ background: step.time20 ? '#409EFF' : '' }">
            <div class="num" :style="{ background: step.time20 ? '#409EFF' : '', color: step.time20 ? '#fff' : '' }">2</div>
            <p class="handle-text" :style="{ color: step.time20 ? '#409EFF' : '' }">待师傅确认</p>
            <p class="handle-time" v-if="step.time20">{{ step.time20 }}</p>
          </div>
          <div class="line"></div>
          <div class="item" style="background: #ddddfd" :style="{ background: step.time30 ? '#409EFF' : '' }">
            <div class="num" :style="{ background: step.time30 ? '#409EFF' : '', color: step.time30 ? '#fff' : '' }">3</div>
            <p class="handle-text" :style="{ color: step.time30 ? '#409EFF' : '' }">退款成功</p>
            <p class="handle-time" v-if="step.time30">{{ step.time30 }}</p>
          </div>
        </div>
        <div class="order-process" v-else-if="[0, 50].includes(refundInfo.handle_state)">
          <div class="item" style="background: #ddddfd">
            <div class="num" style="background: #409eff; color: #fff">1</div>
            <p class="handle-text" style="color: #409eff">提交时间</p>
            <p class="handle-time">{{ step.time0 }}</p>
          </div>
          <div class="line"></div>
          <div class="item" style="background: #ddddfd" :style="{ background: step.time50 ? '#409EFF' : '' }">
            <div class="num" :style="{ background: step.time50 ? '#409EFF' : '', color: step.time50 ? '#fff' : '' }">2</div>
            <p class="handle-text" :style="{ color: step.time50 ? '#409EFF' : '' }">退款关闭</p>
            <p class="handle-time" v-if="step.time50">{{ step.time50 }}</p>
          </div>
        </div>
        <div class="order-process" v-else-if="[0, 10].includes(refundInfo.handle_state)">
          <div class="item" style="background: #ddddfd">
            <div class="num" style="background: #409eff; color: #fff">1</div>
            <p class="handle-text" style="color: #409eff">提交时间</p>
            <p class="handle-time">{{ step.time0 }}</p>
          </div>
          <div class="line"></div>
          <div class="item" style="background: #ddddfd" :style="{ background: step.time10 ? '#409EFF' : '' }">
            <div class="num" :style="{ background: step.time10 ? '#409EFF' : '', color: step.time10 ? '#fff' : '' }">2</div>
            <p class="handle-text" :style="{ color: step.time10 ? '#409EFF' : '' }">待师傅确认</p>
            <p class="handle-time" v-if="step.time10">{{ step.time10 }}</p>
          </div>
        </div>
        <div class="order-process" v-else-if="[0, 10, 53, 51, 52].includes(refundInfo.handle_state)">
          <div class="item" style="background: #ddddfd">
            <div class="num" style="background: #409eff; color: #fff">1</div>
            <p class="handle-text" style="color: #409eff">提交时间</p>
            <p class="handle-time">{{ step.time0 }}</p>
          </div>
          <div class="line"></div>
          <div class="item" style="background: #ddddfd" :style="{ background: step.time10 ? '#409EFF' : '' }">
            <div class="num" :style="{ background: step.time10 ? '#409EFF' : '', color: step.time10 ? '#fff' : '' }">2</div>
            <p class="handle-text" :style="{ color: step.time10 ? '#409EFF' : '' }">待师傅确认</p>
            <p class="handle-time" v-if="step.time10">{{ step.time10 }}</p>
          </div>
          <div class="line"></div>
          <div class="item" style="background: #ddddfd" :style="{ background: step.time53 ? '#409EFF' : '' }">
            <div class="num" :style="{ background: step.time53 ? '#409EFF' : '', color: step.time53 ? '#fff' : '' }">3</div>
            <p class="handle-text" :style="{ color: step.time53 ? '#409EFF' : '' }">平台介入</p>
            <p class="handle-time" v-if="step.time53">{{ step.time53 }}</p>
          </div>
          <div class="line" v-if="step.time51"></div>
          <div class="item" v-if="step.time51" style="background: #ddddfd" :style="{ background: step.time51 ? '#409EFF' : '' }">
            <div class="num" :style="{ background: step.time51 ? '#409EFF' : '', color: step.time51 ? '#fff' : '' }">4</div>
            <p class="handle-text" :style="{ color: step.time51 ? '#409EFF' : '' }">退款成功</p>
            <p class="handle-time" v-if="step.time51">{{ step.time51 }}</p>
          </div>
          <div class="line" v-if="step.time52"></div>
          <div class="item" v-if="step.time52" style="background: #ddddfd" :style="{ background: step.time52 ? '#409EFF' : '' }">
            <div class="num" :style="{ background: step.time52 ? '#409EFF' : '', color: step.time52 ? '#fff' : '' }">4</div>
            <p class="handle-text" :style="{ color: step.time52 ? '#409EFF' : '' }">驳回申请</p>
            <p class="handle-time" v-if="step.time52">{{ step.time52 }}</p>
          </div>
        </div>
        <div class="order-status">
          <div class="status">
            订单状态：
            {{
              refundInfo.handle_state == 0
                ? '待师傅确认'
                : refundInfo.handle_state == 10
                ? '师傅驳回'
                : refundInfo.handle_state == 53
                ? '平台介入处理'
                : refundInfo.handle_state == 52
                ? '平台驳回申请'
                : refundInfo.handle_state == 51
                ? '平台同意退款'
                : refundInfo.handle_state == 20
                ? '师傅同意退款'
                : refundInfo.handle_state == 30
                ? '退款成功'
                : '退款关闭'
            }}
          </div>
          <div class="btn" v-if="refundInfo.handle_state == 53">
            <el-button type="primary" @click="showHandleRefund = !0">平台介入</el-button>
          </div>
          <div class="btn" v-if="refundInfo.handle_state == 20">
            <el-button type="primary" @click="showHandleRefund = !0">退款</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="appeal-info">
      <div class="title">维权信息</div>
      <div class="appeal-content">
        <p>申请时间：{{ getDateformat(refundInfo.create_time) }}</p>
        <p>处理方式：{{ refundInfo.state_desc }}</p>
        <p>退款金额：{{ refundInfo.refund_amount }}</p>
        <p>退款说明：{{ refundInfo.user_reason }}</p>
        <p>
          上传图片：
          <el-image :src="item" :preview-src-list="refundInfo.user_imagess" v-for="(item, index) in refundInfo.user_imagess" :key="index"></el-image>
        </p>
      </div>
    </div>
    <div class="buyers-info">
      <div class="title">买家信息</div>
      <div class="buyers-content">
        <p>付款人：{{ info.consignee || '--' }}</p>
        <p>付款人电话：{{ info.consignee_mobile || '--' }}</p>
      </div>
    </div>
    <div class="service-info">
      <div class="title">服务信息</div>
      <table v-if="info.goods_type != 1">
        <thead>
          <tr>
            <th>服务信息</th>
            <th>服务类别</th>
            <th v-for="item in info.extend_order_goods[0].sku_jsons" :key="item">{{ item.title }}</th>
            <th>施工条件</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="info">
              <el-image style="width: 50px; height: 50px" :src="info.extend_order_goods[0].service_picture"></el-image>
              <span>{{ info.extend_order_goods[0].service_title }}</span>
            </td>
            <td>{{ info.goods_type == 1 ? '快速需求' : info.goods_type == 2 ? '找安装' : info.goods_type == 3 ? '做广告' : '做设计' }}</td>
            <td v-for="item in info.extend_order_goods[0].sku_jsons" :key="item">{{ item.content }}</td>
            <td>{{ info.consignee_conditions_text || '--' }}</td>
          </tr>
        </tbody>
      </table>
      <table v-if="info.goods_type == 2 || info.goods_type == 3">
        <thead>
          <tr>
            <th>现场联系人</th>
            <th>现场联系人电话</th>
            <th>预约时间</th>
            <th>服务地址</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ info.extend_order_extend.reciver_infos.name }}</td>
            <td>{{ info.extend_order_extend.reciver_infos.phone }}</td>
            <td>{{ getDateformat(info.consignee_time) }}</td>
            <td>{{ info.extend_order_extend.reciver_infos.combine_detail.replace(/\s+/g, '') }}{{ info.extend_order_extend.reciver_infos.address }}</td>
          </tr>
        </tbody>
      </table>
      <div class="img">
        <span>现场图片/技术文件：</span>
        <el-image v-for="(item, index) in info.extend_order_extend.scene_picture" :key="index" :src="item" :preview-src-list="info.extend_order_extend.scene_picture"></el-image>
      </div>
      <div class="message">现场描述：{{ info.message }}</div>
      <div class="contract" v-if="info.state > 10 && info.contract">
        <span>相关合同：</span>
        <span v-if="!JSON.parse(info.contract).length">--</span>
        <el-image :src="item" :preview-src-list="JSON.parse(info.contract)" v-for="(item, index) in JSON.parse(info.contract)" :key="index"></el-image>
      </div>
    </div>
    <div class="meet-info" v-if="info.state == 10">
      <div class="title">派单/接单信息</div>
      <table>
        <thead>
          <tr>
            <th>服务类型</th>
            <th>接单师傅</th>
            <th>接单师傅电话</th>
            <th>报价价格</th>
            <th>派单情况</th>
            <th>状态</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in info.extend_order_follow" :key="index">
            <td>{{ item.cur_info.service_type == 1 ? '广告类' : item.cur_info.service_type == 2 ? '安装类' : '设计类' }}</td>
            <td>{{ item.cur_info.name }}</td>
            <td>{{ item.cur_info.mobile }}</td>
            <td>{{ item.prequotation }}</td>
            <td>{{ item.order_case == 0 ? '师傅抢单' : item.order_case == 1 ? '会员派单' : '平台派单' }}</td>
            <td>{{ item.order_status == 0 ? '待沟通' : item.order_status == 1 ? '待报价' : '待支付' }}</td>
          </tr>
          <tr v-if="!info.extend_order_follow.length">
            <td colspan="8">暂无内容</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="construction-info" v-if="info.state >= 20">
      <div class="title">施工信息</div>
      <table>
        <thead>
          <tr>
            <th>服务类型</th>
            <th>接单师傅（创建者）</th>
            <th>接单师傅电话</th>
            <th>支付金额</th>
            <th>抵达时间</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ info.receiving_cur_info.service_type == 1 ? '广告类' : info.receiving_cur_info.service_type == 2 ? '安装类' : '设计类' }}</td>
            <td>{{ info.receiving_cur_info.name }}</td>
            <td>{{ info.receiving_cur_info.mobile }}</td>
            <td>{{ info.amount }}</td>
            <td>{{ getDateformat(info.receiving_cur_info.create_time) }}</td>
          </tr>
        </tbody>
      </table>
      <div class="construction-img" v-if="info.state >= 30">
        <div class="title">施工照片</div>
        <div class="img-type">
          <div class="img">
            <span>确认服务：</span>
            <div>
              <p>{{ info.extend_order_extend.complete_commencement }}</p>
              <el-image :src="item" :preview-src-list="info.extend_order_extend.commencement_pictures" v-for="(item, index) in info.extend_order_extend.commencement_pictures" :key="index"></el-image>
            </div>
          </div>
          <div class="img">
            <span>进行中：</span>
            <div>
              <p>{{ info.extend_order_extend.ongoing_commencement }}</p>
              <el-image :src="item" :preview-src-list="info.extend_order_extend.ongoing_pictures" v-for="(item, index) in info.extend_order_extend.ongoing_pictures" :key="index"></el-image>
            </div>
          </div>
          <div class="img" v-if="info.state == 50">
            <span>验收照片：</span>
            <div>
              <p>{{ info.extend_order_extend.confirm_commencement }}</p>
              <el-image :src="item" :preview-src-list="info.extend_order_extend.complete_pictures" v-for="(item, index) in info.extend_order_extend.complete_pictures" :key="index"></el-image>
            </div>
          </div>
        </div>
      </div>
    </div>
    <handle-refund v-if="showHandleRefund" :refund_amount="refundInfo.refund_amount" :state="refundInfo.handle_state" :id="refundInfo.id"></handle-refund>
  </el-main>
</template>

<script>
import { getDateformat } from '@/util/getDate';
import handleRefund from './components/handleRefund';
export default {
  components: {
    handleRefund,
  },
  data() {
    return {
      showHandleRefund: !1,
      info: {},
      refundInfo: {},
      step: {
        time0: 0,
        time10: 0,
        time20: 0,
        time30: 0,
        time50: 0,
        time51: 0,
        time52: 0,
        time53: 0,
      },
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    getDateformat(val) {
      return getDateformat(val);
    },
    getProcessName(state) {
      let name = null;
      if (state == 0) {
        name = '提交时间';
      }
      if (state == 10 || state == 20) {
        name = '待师傅确认';
      }
      if (state == 53) {
        name = '平台介入处理';
      }
      if (state == 52) {
        name = '申请驳回';
      }
      if (state == 50) {
        name = '退款关闭';
      }
      if (state == 30 || state == 51) {
        name = '退款成功';
      }
      return name;
    },
    getInfo() {
      this.$axios
        .post(this.$api.samecity.service.orderRefundInfo, {
          id: this.$route.query.id,
        })
        .then(res => {
          if (res.code == 0) {
            let refundInfo = res.result.info;
            let step = this.step;
            refundInfo.log_info = refundInfo.log_info.reverse();
            refundInfo.log_info.map(item => {
              if (item.order_refund_state == 0) {
                step.time0 = getDateformat(item.create_time);
              }
              if (item.order_refund_state == 10) {
                step.time10 = getDateformat(item.create_time);
              }
              if (item.order_refund_state == 20) {
                step.time20 = getDateformat(item.create_time);
              }
              if (item.order_refund_state == 30) {
                step.time30 = getDateformat(item.create_time);
              }
              if (item.order_refund_state == 50) {
                step.time50 = getDateformat(item.create_time);
              }
              if (item.order_refund_state == 51) {
                step.time51 = getDateformat(item.create_time);
              }
              if (item.order_refund_state == 52) {
                step.time52 = getDateformat(item.create_time);
              }
              if (item.order_refund_state == 53) {
                step.time53 = getDateformat(item.create_time);
              }
            });
            this.info = refundInfo.order_info;
            this.refundInfo = refundInfo;
          }
        });
    },
  },
};
</script>

 <style lang="less" scoped>
.el-main {
  background: #fff;
  font-size: 14px;
  table {
    width: 100%;
    border: 1px solid #f8f9fa;
    margin-bottom: 20px;
    thead {
      background: #f8f9fa;
      tr th {
        padding: 15px;
        text-align: center;
      }
    }
    tbody {
      tr td {
        padding: 15px;
        text-align: center;
      }
    }
  }
  .title {
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
    margin-bottom: 20px;
  }
  .appeal-info {
    .appeal-content {
      display: flex;
      flex-wrap: wrap;
      p {
        margin-right: 100px;
        margin-bottom: 20px;
        &:last-child {
          display: flex;
          .el-image {
            width: 50px;
            height: 50px;
            margin-right: 15px;
          }
        }
      }
    }
  }
  .order-info {
    margin-bottom: 20px;
    .order-content {
      .order-title {
        display: flex;
        height: 47px;
        background: #f0f0f5;
        align-items: center;
        padding-left: 24px;
        p {
          font-size: 14px;
          margin-right: 40px;
          span {
            font-weight: 400;
            color: #17171a;
          }
        }
      }
    }
    .order-process {
      display: flex;
      height: 190px;
      align-items: center;
      justify-content: center;
      .box {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .line {
        height: 4px;
        width: 120px;
        background: #f0f0f5;
      }
      .item {
        background: #fff;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .num {
          width: 32px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          background: #c8c8cc;
          border-radius: 50%;
          color: #ffffff;
          font-size: 14px;
          font-weight: bold;
        }
        .handle-text {
          width: 100px;
          text-align: center;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: -25px;
          font-size: 14px;
          font-weight: bold;
          color: #969699;
        }
        .handle-time {
          width: 120px;
          position: absolute;
          left: 50%;
          text-align: center;
          transform: translateX(-50%);
          bottom: -25px;
          font-size: 12px;
          font-weight: 400;
          color: #969699;
        }
      }
    }
    .order-status {
      padding: 30px 0;
      background: #f0f0f5;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .status {
        font-size: 16px;
        font-weight: bold;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        color: #969699;
      }
      .btn {
        margin-top: 18px;
      }
    }
  }
  .buyers-info {
    margin-bottom: 20px;
    .buyers-content {
      display: flex;
      p {
        margin-right: 40px;
      }
    }
  }
  .service-info {
    margin-bottom: 20px;
    table {
      .info {
        display: flex;
        align-items: center;
        .el-image {
          margin-right: 15px;
        }
      }
    }
    .img,
    .message,
    .contract {
      padding-left: 100px;
    }
    .message {
      margin-top: 20px;
    }
    .img,
    .contract {
      display: flex;
      align-items: center;
      .el-image {
        width: 50px;
        height: 50px;
        margin-right: 15px;
      }
    }
    .contract {
      margin-top: 20px;
    }
  }
  .construction-img {
    display: flex;
    .img-type {
      margin-left: 30px;
      .img {
        margin-bottom: 20px;
        display: flex;
        span {
          width: 80px;
        }
        & > div {
          p {
            margin-bottom: 15px;
          }
          .el-image {
            width: 50px;
            height: 50px;
            margin-right: 15px;
          }
        }
      }
    }
  }
}
</style>